/*global $*/
/*eslint no-undef: "error"*/

export default class ProductTab {
    classItem = 'product-tab--item'
    classButton = 'product-tab--button'
    classButtonActive = 'product-tab__tab-active'
    classBlock = 'product-tab--block'
    classBlockActive = 'product-tab__block-active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')

            $(this).closest(`.${self.classItem}`).find(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)

            $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}`).removeClass(self.classBlockActive)
            $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}[data-block='${block}']`).addClass(self.classBlockActive)
        })
    }
}
