/*global $*/
/*eslint no-undef: "error"*/
export default class MenuMobile {
    classItem = 'menu-mobile--item'
    classBlock = 'menu-mobile--block'
    classButton = 'menu-mobile--button'

    classBlockOpen = 'menu-mobile__item-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}`).not($(this).closest(`.${self.classBlock}`)).removeClass(self.classBlockOpen)
            $(this).closest(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })
    }
}
