/*global $*/
/*eslint no-undef: "error"*/

export default class ProductGrid {
    classItem = 'product-grid--item'
    classButton = 'product-grid--button'
    classList = 'product-grid--list'
    classProduct = 'product-grid--product'
    classTop = 'product-grid--top'

    classButtonActive = 'product-grid__button-active'
    classTopHidden = 'product-top---hidden'

    constructor () {
        let self = this
        let curTypeRow = 'row'
        let curTypeColumn = 'column'

        $(`.${self.classButton}`).click(function () {
            let curType = $(this).data('type')

            $(this).closest(`.${self.classItem}`).find(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)

            if (curType === 'column') {
                $(`.${self.classList}`).removeClass('product-list-' + curTypeRow).addClass('product-list-' + curTypeColumn)
                $(`.${self.classProduct}`).removeClass('product-' + curTypeRow).addClass('product-' + curTypeColumn)

                $(`.${self.classTop}`).addClass(self.classTopHidden)
            }

            if (curType === 'row') {
                $(`.${self.classList}`).removeClass('product-list-' + curTypeColumn).addClass('product-list-' + curTypeRow)
                $(`.${self.classProduct}`).removeClass('product-' + curTypeColumn).addClass('product-' + curTypeRow)

                $(`.${self.classTop}`).removeClass(self.classTopHidden)
            }
        })

        if ($(window).width() <= 751) {
            $(`.${self.classButton}-2`).click()
            console.log($(window).width())
        }

        $(window).on('resize', function(){
            var win = $(this); //this = window

            if (win.width() <= 751) {
                $(`.${self.classButton}-2`).click()
            }
        })
    }
}
