/*global $*/
/*eslint no-undef: "error"*/
export default class MenuMobile2 {
    classItem = 'menu-mobile2--item'
    classBlock = 'menu-mobile2--block'
    classButton = 'menu-mobile2--button'

    classBlockOpen = 'menu-mobile-sub1__item-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}`).not($(this).closest(`.${self.classBlock}`)).removeClass(self.classBlockOpen)
            $(this).closest(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })
    }
}
