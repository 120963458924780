/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import ProductGrid from '@/assets/js/Product/Grid/ProductGrid'
import Map from '@/assets/js/Map/Map'
import Faq from '@/assets/js/Faq/Faq'
import ProductTab from '@/assets/js/Product/Tab/ProductTab'
import Header from '@/assets/js/Header/Header'
import MenuMobile from '@/assets/js/Menu/Mobile/MenuMobile'
import MenuMobile2 from '@/assets/js/Menu/Mobile/2/MenuMobile2'
import ButtonTop from '@/assets/js/Button/Top/ButtonTop'
import Show from '@/assets/js/Show/Show'
import AppFormPhone from '@/components/Form/AppFormPhone.vue'
import AppFormOrder from '@/components/Form/AppFormOrder.vue'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-fancybox]', {
        mainClass: 'fancybox--popup'
    })

    new SwiperSlider()
    new ProductGrid()
    new Map()
    new Faq()
    new ProductTab()
    new Header()
    new MenuMobile()
    new MenuMobile2()
    new ButtonTop()
    new Show()

    if(document.getElementById('block--form-phone') != null) {
        createApp({})
            .component('block-form-phone', AppFormPhone)
            .use(Maska)
            .use(form)
            .mount('#block--form-phone')
    }

    if(document.getElementById('block--form-order') != null) {
        createApp({})
            .component('block-form-order', AppFormOrder)
            .use(Maska)
            .use(form)
            .mount('#block--form-order')
    }
})
